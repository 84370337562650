/* eslint-disable @typescript-eslint/ban-ts-comment */
import "./App.scss";
import RoambeeLogo from "./assets/images/roambee-logo-whitebg.svg";

// @ts-ignore
import { GlobalSearch, Loader } from "@roambee/client-styleguide";
// @ts-ignore
import { API, useAuthUser, setRoutes, EventEmitter } from "@roambee/client-utility";
import { Suspense, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { API_PATH } from "./configs/APIConfig";
import routes from "./routes";

interface Account {
	title: string;
	uuid: string;
	users: { email: string }[];
}

interface User {
	name: string;
	account?: {
		data?: {
			title: string;
		};
		uuid?: string;
	};
}

interface MenuItem {
	lable: string;
	clickHandler: () => void;
}

interface HeaderProps {
	user: User;
	accounts: Account[];
	account: { label: string; id: string };
	handleSwitchAccount: (event: unknown, newValue: { id: string }) => void;
	menuItems: MenuItem[];
}

function Header({ user, accounts, account, handleSwitchAccount, menuItems }: HeaderProps): JSX.Element {
	return (
		<GlobalSearch
			className="header"
			user={user}
			showLogo={true}
			logo={RoambeeLogo}
			showSearch={false}
			showNotification={false}
			title=""
			menuItems={menuItems}
			accounts={accounts.map((account) => ({
				label: account.title,
				id: account.uuid,
			}))}
			account={account}
			handleSwitchAccount={handleSwitchAccount}
		/>
	);
}

function App(): JSX.Element {
	const navigate = useNavigate();
	const { data: user, authenticated, loading } = useAuthUser();
	const [accounts, setAccounts] = useState<Account[]>([]);
	const [account, setAccount] = useState<{ label: string; id: string }>({
		label: "",
		id: "",
	});

	const menuItems: MenuItem[] = [
		{
			lable: "Logout",
			clickHandler: () => {
				// logout from server
				API("POST", API_PATH.LOGOUT, {}).then((res) => {
					if (res.post_logout_url) {
						window.location.href =
							res.post_logout_url +
							"?redirect_uri=" +
							encodeURIComponent(window.location.origin);
					} else {
						navigate("/auth");
					}
				});
			},
		},
	];

	setRoutes(routes);

	useEffect(() => {
		// Only navigate if we've finished loading and still not authenticated
		if (!loading) {
			if (!authenticated) {
				navigate("/auth");
				return;
			}

			if (authenticated && user) {
				API("GET", "/accounts?quick_view=true&all=true")
					.then((result) => {
						if (result.rows && result.rows.length) {
							setAccounts(result.rows);
						}
						if (user?.account?.data) {
							setAccount({
								label: user.account.data.title,
								id: user.account.uuid || "",
							});
						}
					})
					.catch((error) => {
						if (error.status === 404 && error.message) {
							// eslint-disable-next-line no-console
							console.error(error.message);
						} else {
							// eslint-disable-next-line no-console
							console.error("Error:", error);
							EventEmitter.emit("showSnackbar", {
								id: "error",
								leftIcon: true,
								message: error?.message || "Something went wrong!",
								variant: "error",
								ariaLabel: "close button",
								position: "top-right",
							});
						}
					});
			}
		}
	}, [authenticated, loading, user, navigate]);

	const handleSwitchAccount = async (
		event: unknown,
		newValue: { id: string }
	): Promise<void> => {
		const newCustomerDetails = {
			email: "",
			account_uuid: "",
		};

		accounts.forEach((account) => {
			if (account.uuid === newValue.id) {
				newCustomerDetails.email = account.users[0].email;
				newCustomerDetails.account_uuid = account.uuid;
			}
		});

		await API("POST", "/admin/delegate", newCustomerDetails).then(() => {
			window.location.reload();
		});
	};

	return (
		<section id="main-layout">
			{authenticated && (
				<section id="header">
					<Header
						user={user as User}
						accounts={accounts}
						account={account}
						handleSwitchAccount={handleSwitchAccount}
						menuItems={menuItems}
					/>
				</section>
			)}
			{authenticated && (
				<section id="container-layout">
					<Suspense fallback={<Loader />}>
						<Routes>
							{routes.map((route, idx) => (
								<Route key={idx} path={route.path} element={<route.element />} />
							))}
						</Routes>
					</Suspense>
				</section>
			)}
		</section>
	);
}

export default App;
