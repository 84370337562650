import { createElement, lazy } from "react";
import { Home } from "@carbon/icons-react";
import { RouteType } from ".";

const HomeComponent = lazy(() => import("../modules/home/Home"));

const homeRoutes: RouteType[] = [
	{
		path: "hc20",
		title: "Home",
		element: HomeComponent,
		icon: createElement(Home),
		visible: true
	}
];

export default homeRoutes;
