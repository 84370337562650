// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#single-spa-application\\:\\@roambee\\/client-hc20 {
	#main-layout {
		width: 100vw;
		height: 100%;
		min-height: 100vh;
		display: flex;
		background: linear-gradient(to top, #def3f8, #f6f6f7);
		flex-direction: column;
	}
	#header {
		position: fixed;
		width: inherit;
		height: 3.625rem;
		z-index: 998;
		display: flex;
		.header {
			width: 100%;
			background-color: #f6f6f7;
			.MuiAutocomplete-root {
				min-width: 12rem;
			}
		}
	}
	#container-layout {
		width: 100%;
		padding: 0 10rem;
		margin-top: 5rem;
		display: flex;
		flex: 1;
		flex-direction: column;
		@media (max-width: 768px) {
			padding: 1rem;
			padding-top: 1rem;
		}
	}
	.react-grid-item.react-grid-placeholder {
		background: #fdb933;
		border-radius: 0.75rem;
	}
}
`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;CACC;EACC,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,aAAa;EACb,qDAAqD;EACrD,sBAAsB;CACvB;CACA;EACC,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb;GACC,WAAW;GACX,yBAAyB;GACzB;IACC,gBAAgB;GACjB;EACD;CACD;CACA;EACC,WAAW;EACX,gBAAgB;EAChB,gBAAgB;EAChB,aAAa;EACb,OAAO;EACP,sBAAsB;EACtB;GACC,aAAa;GACb,iBAAiB;EAClB;CACD;CACA;EACC,mBAAmB;EACnB,sBAAsB;CACvB;AACD","sourcesContent":["#single-spa-application\\:\\@roambee\\/client-hc20 {\n\t#main-layout {\n\t\twidth: 100vw;\n\t\theight: 100%;\n\t\tmin-height: 100vh;\n\t\tdisplay: flex;\n\t\tbackground: linear-gradient(to top, #def3f8, #f6f6f7);\n\t\tflex-direction: column;\n\t}\n\t#header {\n\t\tposition: fixed;\n\t\twidth: inherit;\n\t\theight: 3.625rem;\n\t\tz-index: 998;\n\t\tdisplay: flex;\n\t\t.header {\n\t\t\twidth: 100%;\n\t\t\tbackground-color: #f6f6f7;\n\t\t\t.MuiAutocomplete-root {\n\t\t\t\tmin-width: 12rem;\n\t\t\t}\n\t\t}\n\t}\n\t#container-layout {\n\t\twidth: 100%;\n\t\tpadding: 0 10rem;\n\t\tmargin-top: 5rem;\n\t\tdisplay: flex;\n\t\tflex: 1;\n\t\tflex-direction: column;\n\t\t@media (max-width: 768px) {\n\t\t\tpadding: 1rem;\n\t\t\tpadding-top: 1rem;\n\t\t}\n\t}\n\t.react-grid-item.react-grid-placeholder {\n\t\tbackground: #fdb933;\n\t\tborder-radius: 0.75rem;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
