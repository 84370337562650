import { createElement, ReactNode } from "react";
import { Location } from "@carbon/icons-react";

import dashboardRoutes from "./dashboardRoutes";
import beeRoutes from "./beeRoutes";
import shipmentRoutes from "./shipmentRoutes";
import assetRoutes from "./assetRoutes";

export interface RouteType {
	title: string;
	path: string;
	element?: React.ElementType;
	icon?: ReactNode;
	visible?: boolean;
	exact?: boolean;
	children?: RouteType[]; // In case of nested routes
}

const routes: RouteType[] = [
	{
		path: "/hc20",
		element: () => {
			window.location.href = "/hc20/dashboard";
			return null;
		},
		title: ""
	},
	...dashboardRoutes,
	...shipmentRoutes,
	...beeRoutes,
	...assetRoutes,
	{
		path: "hc20/ni",
		title: "Node Intelligence",
		icon: createElement(Location),
		visible: true
	}
];

export default routes;
