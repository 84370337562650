import { createElement, lazy } from "react";
import { Home } from "@carbon/icons-react";
import { RouteType } from ".";

const Dashboard = lazy(() => import("../modules/dashboard/Dashboard"));

const dashboardRoutes: RouteType[] = [
	{
		path: "hc20/dashboard",
		title: "Dashboard",
		element: Dashboard,
		icon: createElement(Home),
		visible: true
	}
];

export default dashboardRoutes;
